import {
  FaBell,
  FaBraille,
  FaChartPie,
  FaCommentAlt,
  FaDollarSign,
  FaEuroSign,
  FaHouseUser,
  FaList,
  FaListOl,
  FaMedapps,
  FaObjectGroup,
  FaOpera,
  FaPaperPlane,
  FaRegStar,
  FaSitemap,
  FaTradeFederation,
  FaTruck,
} from "react-icons/fa";
import {
  FaChartColumn,
  FaGear,
  FaGears,
  FaPaintbrush,
  FaPeopleGroup,
  FaPerson,
  FaRegRectangleList,
} from "react-icons/fa6";
const style = { height: 20, width: 20 };
const navigation = [
  {
    _tag: "CSidebarNavDropdown",
    name: "Thống Kê", // Tên của dropdown
    icon: <FaChartColumn style={{ ...style }} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Trang Chủ",
        to: "/",
        icon: <FaChartPie style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Thu nhập của Admin",
        to: "/wallet",
        icon: <FaEuroSign style={{ ...style }} />,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Người Dùng", // Tên của dropdown
    icon: <FaHouseUser style={{ ...style }} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Quản Trị Viên",
        to: "/employee",
        icon: <FaPeopleGroup style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Thành Viên",
        to: "/member",
        icon: <FaPerson style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Đại Lý Kinh Doanh",
        to: "/collab",
        icon: <FaRegRectangleList style={{ ...style }} />,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Quản Lý", // Tên của dropdown
    icon: <FaMedapps style={{ ...style }} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Nạp tiền",
        to: "/deposit",
        icon: <FaOpera style={{ ...style }} />,
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Nhóm',
      //   to: '/group',
      //   icon: 'cilUserFol style={{...style}}low'
      // },

      {
        _tag: "CSidebarNavItem",
        name: "Yêu Cầu Rút Tiền",
        to: "/transaction",
        icon: <FaDollarSign style={{ ...style }} />,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Bán Hàng", // Tên của dropdown
    icon: <FaTruck style={{ ...style }} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Đánh Giá",
        to: "/feedback",
        icon: <FaCommentAlt style={{ ...style }} />,
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Mã Khuyến Mãi',
      //   to: '/voucher',
      //   icon: 'cilCommentSqu style={{...style}}are'
      // },
      {
        _tag: "CSidebarNavItem",
        name: "Đơn Hàng",
        to: "/order",
        icon: <FaRegStar style={{ ...style }} />,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Hàng Hóa", // Tên của dropdown
    icon: <FaTradeFederation style={{ ...style }} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Danh Mục Sản Phẩm",
        to: "/category",
        icon: <FaSitemap style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Danh Mục Sản Phẩm Con",
        to: "/subcategory",
        icon: <FaListOl style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Sản Phẩm",
        to: "/product",
        icon: <FaPaperPlane style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Sản Phẩm Đợi duyệt",
        to: "/productawaitapprove",
        icon: <FaBraille style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Combo",
        to: "/combo",
        icon: <FaObjectGroup style={{ ...style }} />,
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Cài Đặt Chung", // Tên của dropdown
    icon: <FaGears style={{ ...style }} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Bài Viết",
        to: "/post",
        icon: <FaList style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Thông Báo",
        to: "/notification",
        icon: <FaBell style={{ ...style }} />,
      },
      {
        _tag: "CSidebarNavItem",
        name: "Ảnh Bìa",
        to: "/banner",
        icon: <FaPaintbrush style={{ ...style }} />,
      },
      // {
      //   _tag: "CSidebarNavItem",
      //   name: "Danh sách yêu cầu bán hàng",
      //   to: "/register",
      //   icon: "cilUserFoll style={{...style}}ow",
      // },
      // {
      //   _tag: "CSidebarNavItem",
      //   name: "Trang Vân",
      //   to: "/trangvan",
      //   icon: "cibShopi style={{...style}}fy",
      // },
      {
        _tag: "CSidebarNavItem",
        name: "Cài Đặt",
        to: "/setting",
        icon: <FaGear style={{ ...style }} />,
      },
    ],
  },

  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Cửa Hàng',
  //   to: '/shop',
  //   icon: 'cibShopify'
  // },

  // {
  //   _tag: "CSidebarNavDivider",
  //   className: "m-2",
  // },
];
export default navigation;
