import Swal from "sweetalert2";



export function showError(text, icon, title) {
    console.log("showError -> showError")


    return (
        Swal.fire({
            icon: icon ? icon : 'error',
            title: title ? title : 'Lỗi...',
            text: text
        })
    )
};


