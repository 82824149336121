import React, { useEffect, useState } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch } from "react-redux";
import { login } from "../SLice/Employee";
import { useHistory } from "react-router";
import icon from "src/assets/img/logo.png";

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [object, setobject] = useState([]);

  useEffect(() => {
    const dataAdmin = JSON.parse(localStorage.getItem("user"));
    setobject(dataAdmin);
  }, []);

  const handleLogOut = () => {
    dispatch(login([]));
    localStorage.clear();
    history.push("#/login");
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar avatar-custom">
          <CImg
            src={object?.avatar ? object?.avatar : icon}
            className="c-avatar-img avatar-custom-img"
            alt={object?.username ? object?.username : "logo-default"}
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>{object?.username ? object?.username : ""}</strong>
        </CDropdownItem>

        <CDropdownItem divider />
        <CDropdownItem onClick={handleLogOut} to="/login">
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Đăng Xuất
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
