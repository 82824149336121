import React from "react";

import NavSuper from "./nav-superadmin";
import NavAdmin from "./nav-admin";
import {
  CCreateElement,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import NavContainer from "./NavContainer";

function Nav() {
  console.log("run nav");
  const admin = JSON.parse(localStorage.getItem("user"));

  return (
    <div>
      <NavContainer
        items={admin.type === "SUPERADMIN" ? NavSuper : NavAdmin}
        // components={{
        //   CSidebarNavDivider,
        //   CSidebarNavDropdown,
        //   CSidebarNavItem,
        //   CSidebarNavTitle,
        // }}
      />
    </div>
  );
}

export default Nav;
