import React from "react";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));

const EmployeeList = React.lazy(() => import("./views/employee/EmployeeList"));
const EmployeeCRUD = React.lazy(() => import("./views/employee/EmployeeCRUD"));

const MemberList = React.lazy(() => import("./views/member/MemberList"));
const MemberCRUD = React.lazy(() => import("./views/member/MemberCRUD"));
const MemberDetail = React.lazy(() => import("./views/member/MemberDetail"));

const Deposit = React.lazy(() => import("./views/deposit/Deposit"));

const GroupList = React.lazy(() => import("./views/group/GroupList"));
const GroupCRUD = React.lazy(() => import("./views/group/GroupCRUD"));

const PostList = React.lazy(() => import("./views/post/PostList"));
const PostCRUD = React.lazy(() => import("./views/post/PostCRUD"));
const PostDetail = React.lazy(() => import("./views/post/PostDetail/index"));

const ShopList = React.lazy(() => import("./views/shop/ShopList"));
const ShopCRUD = React.lazy(() => import("./views/shop/ShopCRUD"));

const CategoryList = React.lazy(() => import("./views/category/CategoryList"));
const CategoryCRUD = React.lazy(() => import("./views/category/CategoryCRUD"));

const CategoryPostList = React.lazy(() =>
  import("./views/category_post/CategoryPostList")
);
const CategoryPostCRUD = React.lazy(() =>
  import("./views/category_post/CategoryPostCRUD")
);

const ProductList = React.lazy(() => import("./views/product/ProductList"));
const ProductListAwaitApprove = React.lazy(() =>
  import("./views/product/ProductListAwaitApprove")
);

const ProductCRUD = React.lazy(() => import("./views/product/ProductCRUD"));
const ProductDetail = React.lazy(() => import("./views/product/ProductDetail"));
const ComboCRUD = React.lazy(() => import("./views/combo/CRUDCombo"));
const ComboUpdate = React.lazy(() => import("./views/combo/UpdateCombo"));
const ListCombo = React.lazy(() => import("./views/combo/ListCombo"));

const FeedbackList = React.lazy(() => import("./views/feedback/FeedbackList"));
// const FeedbackCRUD = React.lazy(() => import('./views/feedback/FeedbackCRUD'));

// const VoucherList = React.lazy(() => import('./views/voucher/VoucherList'));
// const VoucherCRUD = React.lazy(() => import('./views/voucher/VoucherCRUD'));

const OrderList = React.lazy(() => import("./views/order/OrderList"));
const OrderCRUD = React.lazy(() => import("./views/order/OrderCRUD"));
const OrderDetail = React.lazy(() => import("./views/order/OrderDetail"));

const NotificationList = React.lazy(() =>
  import("./views/notification/NotificationList")
);
const NotificationCRUD = React.lazy(() =>
  import("./views/notification/NotificationCRUD")
);

const CollabList = React.lazy(() => import("./views/collab/CollabList"));
const CollabCRUD = React.lazy(() => import("./views/collab/CollabCRUD"));
const CollabDetail = React.lazy(() => import("./views/collab/CollabDetail"));

const TransactionList = React.lazy(() =>
  import("./views/transaction/TransactionList")
);
const TransactionCRUD = React.lazy(() =>
  import("./views/transaction/TransactionCRUD")
);

const SettingList = React.lazy(() => import("./views/setting/SettingList"));
const SettingCRUD = React.lazy(() => import("./views/setting/SettingCRUD"));

const WalletList = React.lazy(() => import("./views/wallet/WalletList"));
const WalletCRUD = React.lazy(() => import("./views/wallet/WalletCRUD"));

const BannerList = React.lazy(() => import("./views/banner/BannerList"));
const BannerCRUD = React.lazy(() => import("./views/banner/BannerCRUD"));

const SubCategoryList = React.lazy(() =>
  import("./views/subcategory/SubCategoryList")
);
const SubCategoryCRUD = React.lazy(() =>
  import("./views/subcategory/SubCategoryCRUD")
);
const SubCategoryDetail = React.lazy(() =>
  import("./views/subcategory/SubCategoryDetail")
);

const KeywordList = React.lazy(() => import("./views/keyword/KeywordList"));
const KeywordCRUD = React.lazy(() => import("./views/keyword/KeywordCRUD"));

const RegisterList = React.lazy(() => import("./views/register/RegisterList"));
const RegisterCRUD = React.lazy(() => import("./views/register/RegisterCRUD"));

const TrangVanList = React.lazy(() => import("./views/trangvan/TrangVanList"));

const routes = [
  { path: "/", exact: true, name: "Trang Chủ", component: Dashboard },

  {
    path: "/notification",
    name: "Thông Báo",
    component: NotificationList,
    exact: true,
  },
  { path: "/notification/create", name: "Tạo", component: NotificationCRUD },
  {
    path: "/notification/update/:id",
    name: "Chỉnh sửa",
    component: NotificationCRUD,
  },

  { path: "/member", name: "Thành Viên", component: MemberList, exact: true },
  { path: "/member/create", name: "Tạo", component: MemberCRUD },
  { path: "/member/update/:id", name: "Chỉnh sửa", component: MemberCRUD },
  { path: "/member/detail/:id", name: "Chi tiết", component: MemberDetail },

  { path: "/deposit", name: "Nạp Tiền", component: Deposit, exact: true },

  { path: "/group", name: "Nhóm", component: GroupList, exact: true },
  { path: "/group/create", name: "Tạo", component: GroupCRUD },
  { path: "/group/update/:id", name: "Chỉnh sửa", component: GroupCRUD },

  {
    path: "/transaction",
    name: "Giao Dịch",
    component: TransactionList,
    exact: true,
  },
  // { path: "/transaction/create", name: "Tạo", component: TransactionCRUD },
  {
    path: "/transaction/update/:id",
    name: "Chỉnh sửa",
    component: TransactionCRUD,
  },

  { path: "/shop", name: "Cửa Hàng", component: ShopList, exact: true },
  { path: "/shop/create", name: "Tạo", component: ShopCRUD },
  { path: "/shop/update/:id", name: "Chỉnh sửa", component: ShopCRUD },

  { path: "/post", name: "Bài Viết", component: PostList, exact: true },
  { path: "/post/create", name: "Tạo", component: PostCRUD, exact: true },
  { path: "/post/:id", name: "Xem", component: PostDetail, exact: true },
  {
    path: "/post/update/:id",
    name: "Chỉnh sửa",
    component: PostCRUD,
    exact: true,
  },

  { path: "/setting", name: "Cài Đặt", component: SettingList, exact: true },
  // { path: "/setting/create", name: "Tạo", component: SettingCRUD },
  { path: "/setting/update/:id", name: "Chỉnh sửa", component: SettingCRUD },

  {
    path: "/wallet",
    name: "Thu Nhập Của Admin",
    component: WalletList,
    exact: true,
  },
  { path: "/wallet/create", name: "Tạo", component: WalletCRUD },
  { path: "/wallet/update/:id", name: "Chỉnh sửa", component: WalletCRUD },

  { path: "/banner", name: "Ảnh bìa", component: BannerList, exact: true },
  { path: "/banner/create", name: "Tạo", component: BannerCRUD },
  { path: "/banner/update/:id", name: "Chỉnh sửa", component: BannerCRUD },

  {
    path: "/category",
    name: "Danh Mục Sản Phẩm",
    component: CategoryList,
    exact: true,
  },
  { path: "/category/create", name: "Tạo", component: CategoryCRUD },
  { path: "/category/update/:id", name: "Chỉnh sửa", component: CategoryCRUD },

  {
    path: "/category_post",
    name: "Danh Sách Loại Bài Viết",
    component: CategoryPostList,
    exact: true,
  },
  { path: "/category_post/create", name: "Tạo", component: CategoryPostCRUD },
  {
    path: "/category_post/update/:id",
    name: "Chỉnh sửa",
    component: CategoryPostCRUD,
  },

  {
    path: "/subcategory",
    name: "Danh Mục Sản Phẩm Con",
    component: SubCategoryList,
    exact: true,
  },
  { path: "/subcategory/create", name: "Tạo", component: SubCategoryCRUD },
  {
    path: "/subcategory/update/:id",
    name: "Chỉnh sửa",
    component: SubCategoryCRUD,
  },
  {
    path: "/subcategory/detail/:id",
    name: "Chi tiết",
    component: SubCategoryDetail,
  },

  { path: "/keyword", name: "Từ Khóa", component: KeywordList, exact: true },
  { path: "/keyword/create", name: "Tạo", component: KeywordCRUD },
  { path: "/keyword/update/:id", name: "Chỉnh sửa", component: KeywordCRUD },

  {
    path: "/product",
    name: "Sản Phẩm",
    component: ProductList,
    exact: true,
  },

  {
    path: "/productawaitapprove",
    name: "Sản Phẩm đợi duyệt",
    component: ProductListAwaitApprove,
    exact: true,
  },
  {
    path: "/combo/create",
    name: "Tạo",
    component: ComboCRUD,
    exact: true,
  },
  {
    path: "/combo/:id",
    name: "Sửa",
    component: ComboUpdate,
    exact: true,
  },
  {
    path: "/combo",
    name: "Combo",
    component: ListCombo,
    exact: true,
  },
  // { path: "/product/create", name: "Tạo", component: ProductCRUD },
  // { path: "/product/update/:id", name: "Chỉnh sửa", component: ProductCRUD },
  { path: "/product/detail/:id", name: "Chi tiết", component: ProductDetail },

  // { path: '/voucher', name: 'Mã khuyến mãi', component: VoucherList, exact: true },
  // { path: '/voucher/create', name: 'Tạo', component: VoucherCRUD },
  // { path: '/voucher/update/:id', name: 'Chỉnh sửa', component: VoucherCRUD },

  { path: "/order", name: "Đơn Hàng", component: OrderList, exact: true },
  { path: "/order/create", name: "Tạo", component: OrderCRUD },
  { path: "/order/update/:id", name: "Chỉnh sửa", component: OrderCRUD },
  { path: "/order/detail/:id", name: "Chi tiết", component: OrderDetail },

  { path: "/feedback", name: "Đánh giá", component: FeedbackList, exact: true },
  // { path: '/feedback/create', name: 'Tạo', component: FeedbackCRUD },
  // { path: '/feedback/update/:id', name: 'Chỉnh sửa', component: FeedbackCRUD },

  {
    path: "/collab",
    name: "Đại Lý Kinh Doanh",
    component: CollabList,
    exact: true,
  },
  { path: "/collab/create", name: "Tạo", component: CollabCRUD },
  { path: "/collab/update/:id", name: "Chỉnh sửa", component: CollabCRUD },
  { path: "/collab/detail/:id", name: "Chi tiết", component: CollabDetail },

  // {
  //   path: "/register",
  //   name: "Danh sách yêu cầu bán hàng",
  //   component: RegisterList,
  //   exact: true,
  // },
  // { path: '/register/create', name: 'Tạo', component: RegisterCRUD },
  { path: "/register/update/:id", name: "Chỉnh sửa", component: RegisterCRUD },

  // {
  //   path: "/trangvan",
  //   name: "Trang Vân",
  //   component: TrangVanList,
  //   exact: true,
  // },
];
export const routesSuperAdmin = [
  {
    path: "/employee",
    name: "Quản Trị Viên",
    component: EmployeeList,
    exact: true,
  },
  { path: "/employee/create", name: "Tạo", component: EmployeeCRUD },
  { path: "/employee/update/:id", name: "Chỉnh sửa", component: EmployeeCRUD },
];
export default routes;
