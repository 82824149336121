import React from "react";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

import "./NavContainer.scss";
function NavContainer({ items }) {
  const location = useLocation();
  console.log(
    "🧑‍🏭 TrieuNinhHan ~ file: NavContainer.js:4 ~ NavContainer ~ items:",
    items,
    location
  );
  return (
    <Menu className="NavContainer">
      {items.map((item) =>
        item?._tag === "CSidebarNavDropdown" ? (
          <SubMenu
            className="NavContainer-SubMenu"
            defaultOpen={
              item?._children
                ?.map((child) => child.to)
                .includes(location.pathname)
                ? true
                : false
            }
            label={item?.name}
            key={item?.name}
            icon={item?.icon}
          >
            {item?._children?.map((child) => {
              return (
                <MenuItem
                  key={child?.name}
                  icon={child?.icon}
                  className={` NavContainer-MenuItem-Dropdown ${
                    location.pathname === child.to ? "active" : ""
                  } `}
                  component={<Link to={child.to} />}
                >
                  {child?.name}
                </MenuItem>
              );
            })}
          </SubMenu>
        ) : (
          <div>
            <MenuItem
              icon={<div style={{ height: 35, width: 35 }}>{item?.icon}</div>}
              className={`NavContainer-MenuItem ${
                location.pathname === item.to ? "active" : ""
              } `}
              component={<Link to={item.to} />}
            >
              {item?.name}
            </MenuItem>
          </div>
        )
      )}
      <br /> <br /> <br />
    </Menu>
  );
}

export default NavContainer;
