import jwt_decode from "jwt-decode";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { icons } from "./assets/icons";
import { TheLayout } from "./containers";
import { showError } from "./error";
import "./scss/style.scss";
React.icons = icons;
const Login = React.lazy(() => import("./views/pages/login/Login"));

function App() {
  const a = useSelector((state) => state.employee);
  const token = localStorage.getItem("token");
  const tokenDecode = token ? jwt_decode(token) : "";
  let expired = false;
  if (new Date(tokenDecode.exp).getTime() <= Date.now()) {
    expired = true;
  }
  if (expired) {
    localStorage.clear();
    showError(
      "Phiên đăng nhập hết hạn vui lòng đăng nhập lại để truy cập hệ thống"
    );
  }

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        a.length || (localStorage.getItem("user") && !expired) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  return (
    <div>
      <HashRouter>
        <Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login" component={Login} />

            <PrivateRoute exact path="/" component={TheLayout} />

            <PrivateRoute exact path="/employee" component={TheLayout} />
            <PrivateRoute exact path="/employee/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/employee/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/notification" component={TheLayout} />
            <PrivateRoute
              exact
              path="/notification/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/notification/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/member" component={TheLayout} />
            <PrivateRoute exact path="/member/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/member/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/member/detail/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/group" component={TheLayout} />
            {/* <PrivateRoute exact path="/group/create" component={TheLayout} />
            <PrivateRoute exact path="/group/update/:id" component={TheLayout} /> */}

            <PrivateRoute exact path="/transaction" component={TheLayout} />
            <PrivateRoute
              exact
              path="/transaction/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/transaction/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/post" component={TheLayout} />
            <PrivateRoute exact path="/post/create" component={TheLayout} />
            <PrivateRoute exact path="/post/update/:id" component={TheLayout} />

            <PrivateRoute exact path="/shop" component={TheLayout} />
            <PrivateRoute exact path="/shop/create" component={TheLayout} />
            <PrivateRoute exact path="/shop/update/:id" component={TheLayout} />

            <PrivateRoute exact path="/feedback" component={TheLayout} />
            {/* <PrivateRoute exact path="/feedback/create" component={TheLayout} />
            <PrivateRoute exact path="/feedback/update/:id" component={TheLayout} /> */}

            {/* <PrivateRoute exact path="/voucher" component={TheLayout} />
            <PrivateRoute exact path="/voucher/create" component={TheLayout} />
            <PrivateRoute exact path="/voucher/update/:id" component={TheLayout} /> */}

            <PrivateRoute exact path="/category" component={TheLayout} />
            <PrivateRoute exact path="/category/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/category/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/category_post" component={TheLayout} />
            <PrivateRoute
              exact
              path="/category_post/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/category_post/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/subcategory" component={TheLayout} />
            <PrivateRoute
              exact
              path="/subcategory/create"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/subcategory/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/subcategory/detail/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/keyword" component={TheLayout} />
            <PrivateRoute exact path="/keyword/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/keyword/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/product" component={TheLayout} />
            <PrivateRoute
              exact
              path="/productawaitapprove"
              component={TheLayout}
            />
            <PrivateRoute exact path="/product/create" component={TheLayout} />
            <PrivateRoute exact path="/combo" component={TheLayout} />
            <PrivateRoute exact path="/combo/create" component={TheLayout} />
            <PrivateRoute exact path="/combo/:id" component={TheLayout} />

            <PrivateRoute
              exact
              path="/product/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/product/detail/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/order" component={TheLayout} />
            <PrivateRoute exact path="/order/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/order/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/order/detail/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/collab" component={TheLayout} />
            <PrivateRoute exact path="/collab/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/collab/update/:id"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/collab/detail/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/banner" component={TheLayout} />
            <PrivateRoute exact path="/banner/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/banner/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/setting" component={TheLayout} />
            <PrivateRoute exact path="/setting/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/setting/update/:id"
              component={TheLayout}
            />

            <PrivateRoute exact path="/wallet" component={TheLayout} />
            <PrivateRoute exact path="/wallet/create" component={TheLayout} />
            <PrivateRoute
              exact
              path="/wallet/update/:id"
              component={TheLayout}
            />
            <PrivateRoute exact path="/deposit" component={TheLayout} />

            <PrivateRoute exact path="/register" component={TheLayout} />
            {/* <PrivateRoute exact path="/register/create" component={TheLayout} /> */}
            {/* <PrivateRoute
              exact
              path="/register/update/:id"
              component={TheLayout}
            /> */}

            {/* <PrivateRoute exact path="/trangvan" component={TheLayout} /> */}
            <PrivateRoute exact path="*" component={TheLayout} />
            {/* Post Detail */}
            <PrivateRoute exact path="/post/:slug" component={TheLayout} />
            <Route path="/login" name="Login" component={Login} />
          </Switch>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
