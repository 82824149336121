import { configureStore } from '@reduxjs/toolkit'
import sidebarShow from './SLice/responsive'
import employee from "./SLice/Employee"
import Modal from './views/Modal';


const rootReducer = {
  sidebarShow : sidebarShow,
  employee : employee,
  modal : Modal,
}

const store = configureStore({
    reducer : rootReducer,
});

export default store;