import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
} from "@coreui/react";
import icon from "../assets/img/logo1.png";
import CIcon from "@coreui/icons-react";

// sidebar nav config
import Nav from "./_nav";
import { set } from "src/SLice/responsive";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);

  return (
    <CSidebar show={show} onShowChange={(val) => dispatch(set(val))}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon
          className="c-sidebar-brand-full p-3"
          src={icon}
          width={256}
          style={{ filter: "brightness(1000%) saturate(0%) contrast(100%)" }}
        />
        <CIcon className="c-sidebar-brand-minimized" src={icon} height={50} />
      </CSidebarBrand>
      <CSidebarNav>
        <Nav />
        {/* <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        /> */}
      </CSidebarNav>
      {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
